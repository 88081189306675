import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// Stars Position
setTimeout(function () {
  let topp = document.getElementById("top")
  function setStars(numS) {
    for (let i = 0; i < numS; i++) {
      let stars = document.createElement("div")
      stars.setAttribute("class", "stars")
      stars.style.left = 100 * Math.random() + "%"
      stars.style.top = 55 * Math.random() + "%"
      topp.appendChild(stars)
    }
  }
  setStars(250)

  // Sun Animation
  let sunset = document.getElementById("sun");
  function synthSun(nmb) {
    for (let i = 0; i < nmb * 2; i++) {
      let sunin = document.createElement("div")
      sunin.setAttribute("class", "sun")
      sunin.style.animationDelay = -.5 * i++ + "s"
      sunset.appendChild(sunin)
    }
  }



  synthSun(8)


}, 0);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
